import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { Link, graphql } from "gatsby"

class IndexPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Seo
          title="My Apps"
          keywords={[
            "blog",
            "javascript",
            "typescript",
            "contractor",
            "software",
            "engineer",
            "react",
            "native",
            "react-native",
            "swift",
            "ios",
            "android",
            "mobile",
            "app",
          ]}
        />
        <h1>
          I'm working on
        </h1>
        <ol style={{ listStyle: `none` }}>
          <li key="focus">
            <article
              className="post-list-item"
              itemScope
              itemType="http://schema.org/Article"
            >
              <header>
                <h2>
                  <Link to={`/apps/focus`} itemProp="url">
                    <span itemProp="headline">focus</span>
                  </Link>
                </h2>
              </header>
              <section>
                <p itemProp="description">
                  App that helps to organize your work or studying in focus and
                  break sessions using the pomodoro method. With focus app, it is
                  easier to keep track of focused time and remember about
                  breaks.
                </p>
              </section>
            </article>
          </li>
        </ol>
      </Layout>
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
